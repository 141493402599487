

.navBar_font{


    // background-color: transparent !important;
    color: white !important;
font-size: large !important;

@media only screen and (max-width: 720px) {
    font-size: medium !important;
  }
}
