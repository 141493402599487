

.nav-tabs {
     
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #010101 !important;

}

.navBarWork_font{


  // background-color: transparent !important;
  color: white !important;
 
}

.NavWorks{

  @media only screen and (max-width: 760px) {
    margin-top: 8%;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 12%;
  }

  @media only screen and (max-width: 460px) {
    margin-top: 18%;
  }
}