* {
    margin: 0;
    padding: 0;
    
}

html {
    font-size: 62, 5%;

}

body{
    background-color:white;

}