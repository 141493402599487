
.arrow {
  // position: absolute;
  top: 89%;
  left: 50%;
  position: fixed;

  @media only screen and (max-width: 720px) {
    top: 88%;
  }
}


.arrow span {
  display: block;
  width: 28px;
  height: 28px;
  border-bottom: 5px solid black;
  border-right: 5px solid black;

  margin: -10px;
  animation: scroll1 2s infinite;

    @media only screen and (max-width: 720px) {
      width: 25px;
      height: 25px;
  }

}

// .arrow span:nth-child(1) {
//   animation-delay: -.1s;
// }

.arrow span:nth-child(2) {
  animation-delay: -.2s;
  
}

.arrow span:nth-child(3) {
  animation-delay: -.4s;
}

@keyframes scroll1 {
 
  0% {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -2px);
      border-bottom: 5px solid white;
      border-right: 5px solid white;
     
  }
  50% {
      opacity: 1;
      // transform: rotate(45deg) translate(-20px, -20px);
  }
  100% {
      opacity: 0;
      transform:  rotate(45deg) translate(20px, 20px);
  }
}


@media only screen and (max-width: 720px) {

  // .arrow span:nth-child(3) {
  //   animation-delay: -.4s;
  // }
  
  // .arrow span:nth-child(2) {
  //   animation-delay: -.2s;
  // }
  
  // .arrow span:nth-child(1) {
  //   animation-delay: -.0s;
  // }

  .arrow span:nth-child(3) {
    animation-delay: -.4s;
    
  }
  
  .arrow span:nth-child(2) {
    animation-delay: -.2s;
  }
  

  @keyframes scroll1 {
    0% {
        opacity: 1;
        transform: rotate(-135deg) translate(-20px, -20px);
        border-bottom: 5px solid white;
        border-right: 5px solid white;
    }
    50% {
        opacity: 1;
        // transform: rotate(-135deg) translate(-20px, -20px);
    }
    100% {
        opacity: 0;
        transform: rotate(-135deg) translate(-20px, -20px);
    }
  }
    
}

