.canvas_container{
    height: 100vh;
    width: 100vw;
  position: absolute;


   
}

.Loading{
  // text-align: center!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  width: 100% !important;
  height: 100vh!important;

}