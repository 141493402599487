

.work_content{
  width: 90%;
  margin: 0 auto;

  margin-top: 5%;

}

.row_cat{
 
  display: flex;
  justify-content:space-between;
  width: 100%;
  margin: 0 auto;
  margin-top: 2%;

 
 
  .work_col {

       display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;

      // width: 100%;

       
  }

}


.workItem{
  margin: 2% 1%;
  width: 48%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  
  
  img{
    width: 100%;
    height: auto;
  }

  .work_detail{

    margin-top: 3%;
    
    .work_title{
      color: white;
      margin-bottom: 0.5%;
      font-size: large;
    }

    .work_tipo{
      color: #7a7a7a;
      font-size: medium;
    }
  }
  .work_detail2{
    margin-top: 2%;
    padding-left: 1%;

    @media only screen and (max-width: 1200px) {
      margin-top: 0.5%;
      padding-left: 5%;
    }

    @media only screen and (max-width: 1100px) {
      margin-top: 0.5%;
      padding-left: 1%;
    }

    @media only screen and (max-width: 820px) {
      margin-top: 0.0%;
      padding-left: 1%;
    }

    @media only screen and (max-width: 500px) {
      margin-top: 0.0%;
      padding-left: 5%;
      }

      // @media only screen and (max-width: 400px) {
      //   margin-top: 0.0%;
      //   padding-left: 1%;
      //   }
      @media only screen and (max-width: 360px) {
        margin-top: 0.0%;
        padding-left: 1%;
        padding-left: 1%;
        }

      // @media only screen and (max-width: 500px) {
      //   margin-top: 0.0%;
      //   padding-left: 7%;
      // }

      // @media only screen and (max-width: 500px) {
      //   margin-top: 0.0%;
      //   padding-left: 2%;
      // }

      .work_title{
      color: white;
      margin-bottom: 0.2%;
     font-size: large;
     @media only screen and (max-width: 500px) {
      font-size: medium;
      }
 
    }

    .work_tipo{
      color: #7a7a7a;
      font-size: medium;
      @media only screen and (max-width: 500px) {
        font-size: small;
        }
    }
  }

}
.color{


  background-color: red;
}


.playerVimeo{
  
  // background-color:black;
  // position:absolute;
  // top:0;
  // left: 0;
  width: 800px;
  height: 450px;

  @media only screen and (max-width: 1800px) {
    width: 750px;
    height: 450px;
  }

  @media only screen and (max-width: 1700px) {
    width: 720px;
    height: 450px;
  }

  @media only screen and (max-width: 1700px) {
    width: 700px;
    height: 400px;
  }

  @media only screen and (max-width: 1600px) {
    width: 650px;
    height: 380px;
  }

  @media only screen and (max-width: 1500px) {
    width: 620px;
    height: 340px;
  }

  @media only screen and (max-width: 1400px) {
    width: 580px;
    height: 340px;
  }

  @media only screen and (max-width: 1300px) {
    width: 530px;
    height: 300px;
  }


  @media only screen and (max-width: 1200px) {
    width: 1000px;
    height: 500px;
  }

  @media only screen and (max-width: 1100px) {
    width: 900px;
    height: 500px;
  }

  @media only screen and (max-width: 992px) {
    width: 850px;
    height: 500px;
  }


  @media only screen and (max-width: 920px) {
    width: 740px;
    height: 440px;
  }

  @media only screen and (max-width: 820px) {
    width: 690px;
    height: 400px;
  }
  
  @media only screen and (max-width: 760px) {
    width: 640px;
    height: 360px;
  }

  @media only screen and (max-width: 720px) {
    width: 600px;
    height: 360px;
  }

  @media only screen and (max-width: 670px) {
    width: 570px;
    height: 340px;
  }

  @media only screen and (max-width: 630px) {
    width: 540px;
    height: 340px;
  }

  @media only screen and (max-width: 600px) {
    width: 500px;
    height: 280px;
  }

  @media only screen and (max-width: 560px) {
    width: 450px;
    height: 250px;
  }

  @media only screen and (max-width: 500px) {
    width: 430px;
    height: 220px;
  }

  @media only screen and (max-width: 460px) {
    width: 380px;
    height: 200px;
  }

  @media only screen and (max-width: 400px) {
    width: 340px;
    height: 180px;
  }

  @media only screen and (max-width: 370px) {
    width: 320px;
    height: 180px;
  }

  @media only screen and (max-width: 360px) {
    width: 300px;
    height: 170px;
  }
}

// .vp-target{
//   width: 900px !important;
// }

