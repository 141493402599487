.navBar_content{

  width: 100%;
  position:absolute;
  top: 0;
 left: 0;


}

.navBar_content2{

  width: 100%;
  position:absolute;
  top: 0;
 left: 0;

 background-color:#101010!important;


}

.resize{
  @media only screen and (max-width: 630px) {
    width: 540px;
    height: 340px;
    // margin: 0 auto;
  }

}
